// React
import { useState, useEffect } from "react";

// Auth
import { authHeader } from "../../_helpers/auth-header";

// Endpoints
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import { updatePermissions } from "../permissions/PermissionsAPI";

export async function createProject(data) {
  data.setDisableSubmit(true);
  data.setErrors({
    Response: { error: false, message: "Saving..." },
  });

  const url = data.URL;
  const payload = {
    ...data.projectData,
  };

  const config = {
    method: data.urlMethod,
    body: JSON.stringify(payload),
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let response = await fetch(url, config);

  // Response OK
  if (response.ok) {
    // New Project Created
    if (data.isProjectCreation) {
      response = await response.json();
      let project = response.data;

      // Add permission for creator user
      const permissionData = {
        payload: [
          {
            userGlobalId: data.user.id, // activeDirectoryId
            userRoleList: ["USER"],
            isRecordDeleted: false,
            companyId: project.companyId,
          },
        ],
        mappingId: project.projectId,
        mappingType: "Project",
        // Unused functions expected by updatePermissions
        setError: () => {},
        updateState: () => {},
      };

      await updatePermissions(permissionData);
      window.location.reload();
    } else {
      window.location.reload();
    }
  } else {
    response = await response.json();

    data.setErrors({
      ...response.errors,
      Response: { error: true, message: response.message },
    });
    // Response Not OK
    data.setDisableSubmit(false);
  }
}

// COMPANIES
export function useCompanies(show) {
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState({});
  useEffect(() => {
    if (show) {
      setLoading(true);
      getCompanies()
        .then((companiesRes) => {
          setCompanies(companiesRes);
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [show]);

  return {
    companies: {
      data: companies,
      loading: loading,
      get: getCompanies,
      set: setCompanies,
    },
  };
}
export async function getCompanies() {
  // COMPANY API
  const url = sharedAPIs().get_companies;

  const config = {
    method: "GET",
    headers: authHeader({ authJson: true, authForm: false, guestJson: false }),
  };
  let erMessage = "Get Companies Error";
  let response = await fetch(url, config);
  if (response.ok) {
    response = await response.json();
  } else {
    response = [];
    console.log(erMessage);
  }

  return response;
}
