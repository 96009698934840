import React, { useState } from "react";
import PDFViewer from "../pdf/PDFViewer";
import { deleteProjectDrawing } from "../../api/projects/ProjectDrawingsAPI";
import { hasRoles } from "../../utils/roles";
import isEmpty from "../../validation/is-empty";
import Spinner from "../common/Spinner";
import { translate } from "../../utils/translation";
import "./Drawings.css";
import PropTypes from "prop-types";

Drawings.propTypes = {
  project: PropTypes.object,
  CP: PropTypes.object,
  CPs: PropTypes.array,
  user: PropTypes.object,
};

export default function Drawings(props) {
  const { project } = props;
  const { CP } = props;
  const { CPs } = props;
  const { user } = props;

  if (isEmpty(CP)) {
    return (
      <div className="project-budget">
        {translate(
          "Cost Plan Drawings are available after a Cost Plan has been uploaded"
        )}
      </div>
    );
  }

  if (isEmpty(CP.drawings)) {
    return (
      <div className="project-budget">
        {translate(
          "No Cost Plan Drawings has been uploaded. Please click Upload PDF button to add new documents"
        )}
      </div>
    );
  }

  let displayDrawings = [];
  if (CP.drawings != null) {
    CP.drawings.forEach((d) => {
      displayDrawings.push(
        <div key={d.id}>
          <PDFViewer
            documentId={d.document_id} // Remove the double quote present within the single quote
            deleteButton={
              <DeleteDrawingButton
                drawing_id={d.id}
                user={user}
                project={project}
                CPs={CPs}
                cost_plan_id={CP.cost_plan_id}
              />
            }
          />
        </div>
      );
    });
  }
  return <div className="estimate-drawings">{displayDrawings}</div>;
}

DeleteDrawingButton.propTypes = {
  drawing_id: PropTypes.string,
  user: PropTypes.object,
  project: PropTypes.object,
  CPs: PropTypes.array,
  cost_plan_id: PropTypes.string,
};

function DeleteDrawingButton(props) {
  const { drawing_id } = props;
  const { user } = props;
  const { project } = props;
  const { CPs } = props;
  const { cost_plan_id } = props;
  const [isDeleting, setIsDeleting] = useState(false);

  if (!hasRoles(user.roles, ["CostManager"])) {
    return null;
  }

  if (project.project_phase !== "COST_PLANNING") {
    return null;
  }

  if (isDeleting) {
    return <Spinner width={"20px"} />;
  }

  return (
    <button
      className="pdf-delete-button"
      onClick={async () => {
        const confirm = window.confirm(
          `Confirm delete this PDF? This cannot be undone.`
        );

        if (!confirm) {
          return;
        }

        setIsDeleting(true);
        await deleteProjectDrawing(drawing_id, project.id, cost_plan_id);
        CPs.set(await CPs.get(project.id, project));
        setIsDeleting(false);
      }}
    >
      {translate("Delete")}
    </button>
  );
}
