import React from "react";
import isEmpty from "../../validation/is-empty";
import { ImageDisplay } from "../images/ImageDisplay";
import { displayInsightImage } from "../../validation/image-clean";
import "./ArticleProfile.css";
import PropTypes from "prop-types";

ArticleProfile.propTypes = {
  selectedArticle: PropTypes.object,
};

export default function ArticleProfile(props) {
  const { selectedArticle } = props;

  const URL = window.location.href;

  if (!URL.includes("article/")) {
    return null;
  }

  if (isEmpty(selectedArticle)) {
    return null;
  }

  return (
    <div className="category-profile">
      <div className="article-profile-image">
        <ImageDisplay
          key={selectedArticle.id}
          image={displayInsightImage(selectedArticle)}
          imageWidth={"160px"}
          imageHeight={"200px"}
          isBezierDisabled={true}
        />
        <div className="article-profile-image-banner">
          <h1 className="display-4" style={{ fontSize: "20px" }}>
            {selectedArticle.title}
          </h1>
        </div>
      </div>
    </div>
  );
}
