import React, { useEffect } from "react";
import InsightCategoryArticlePath from "../path/InsightCategoryArticlePath";
import PDFViewer from "../pdf/PDFViewer";
import "./Article.css";
import PropTypes from "prop-types";

Article.propTypes = {
  article: PropTypes.object,
  setSelectedArticle: PropTypes.object,
};

export default function Article(props) {
  const { article } = props;
  const { setSelectedArticle } = props;

  // Lift state for selected topic
  useEffect(() => {
    setSelectedArticle(article);
  }, [article, setSelectedArticle]);

  const author = {
    name: "",
    surname: "",
    email: "",
    job_title: "",
  };

  author.name = article.author_name;
  author.surname = article.author_surname;
  author.email = article.author_email;
  author.job_title = article.author_title;

  let day = new Date(parseInt(article.timestamp)).toLocaleString("default", {
    day: "numeric",
  });
  let month = new Date(parseInt(article.timestamp)).toLocaleString("default", {
    month: "long",
  });
  let year = new Date(parseInt(article.timestamp)).toLocaleString("default", {
    year: "numeric",
  });

  return (
    <div>
      <InsightCategoryArticlePath
        category={article.category}
        article={article.title}
        categoryID={article.market_insight_id}
        articleID={article.id}
      />
      {/* <Heading text={article.title} fontSize={"50px"} /> */}

      <div className="article-content">
        <div className="article-pdf">
          <PDFViewer documentId={article.pdfId} />
        </div>
        <div className="article-author-container">
          <Heading text={day + " " + month + ", " + year} fontSize={"35px"} />

          <div className="article-author-name">
            {author.name + " " + author.surname}
          </div>
          <div className="article-author-job-title">{author.job_title}</div>
          <a
            href={"mailto:" + author.email + "?subject=" + article.title}
            className="article-author-email"
          >
            <i className="fas fa-envelope" />
          </a>
        </div>
      </div>
    </div>
  );
}

Heading.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
};

function Heading(props) {
  const { text } = props;
  const { fontSize } = props;
  return (
    <div className="article-heading">
      <h1 className="display-4" style={{ fontSize: fontSize }}>
        {text}
      </h1>
    </div>
  );
}
